.nav {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 5rem;
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  left: 0;
  background: var(--clr-primary-3);
  z-index: 15;
}

.nav-center {
  width: 90%;
  margin: 0 auto;
}

.nav-header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 100%;
}

.nav-links {
  display: none;
}

.nav-btn {
  background: transparent;
  border: none;
  height: 50px;
  width: 50px;
  justify-self: end;
  cursor: pointer;
  z-index: 10;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--clr-primary-3);
  z-index: 6;
  display: grid;
  place-items: center;
  transform: translateX(-100%);
  transition: all 1s;
  overflow: hidden;
}

.sidebar-show {
  transform: translateX(0);
}

.close-btn {
  position: absolute;
  top: 2rem;
  right: 5rem;
  cursor: pointer;
  font-size: 3rem;
  color: var(--clr-primary-3);
  background: transparent;
  border: none;
  cursor: pointer;
}

.links {
  text-align: center;
  list-style: none;
  font-size: 1.5rem;
  line-height: 3rem;
  display: flex;
  flex-direction: column;
}

.icons {
  display: grid;
  text-align: center;
  list-style: none;
  grid-template-columns: repeat(3, 1fr);
  font-size: 2rem;
  column-gap: 1rem;
  padding: 2rem 0;
}

.links a,
.icons a {
  color: var(--clr-text);
}

.links a:hover,
.icons a:hover {
  color: var(--clr-primary-1) !important;
}

.logo {
  display: inline-block;
  font-size: 1.5rem;
  border-right: 2px solid rgba(219, 14, 14, 0.75);
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  animation: typewriter 0.5s steps(7) 1s 1 normal both,
    blinkTextCursor 550ms steps(7) infinite normal;
}

.logout-btn {
  color: var(--clr-primary-3);
  background-color: var(--clr-primary-1);
  border: none;
  padding: 0.5rem 0.6rem;
}

.logout-btn:active {
  background-color: var(--clr-primary-2);
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(219, 14, 14, 0.75);
  }

  to {
    border-right-color: transparent;
  }
}
