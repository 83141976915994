.hero {
  min-height: calc(100vh - 5rem);
  display: grid;
  place-items: center;
  position: relative;
  overflow: hidden;
  background-color: #333;
}

.scene {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.scene path {
  fill: var(--clr-primary-2);
}

.info {
  z-index: 4;
}

.info h1 {
  color: var(--clr-primary-3);
  font-size: 4rem;
  line-height: 1;
}

.info h4 {
  color: #8e8e8e;
  line-height: 1;
}

.hero-btn {
  margin-top: 2rem;
  padding: 1rem 2rem;
  cursor: pointer;
  color: var(--clr-primary-3);
  border: 3px solid var(--clr-primary-1);
  background-color: transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.hero-icons a {
  color: var(--clr-primary-3);
}

.hero-btn span {
  transition: 2s all;
}

.hero-btn:hover span {
  color: var(--clr-primary-3);
}

.hero-btn::after {
  content: "";
  background-color: var(--clr-primary-1);
  width: 110%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: translateY(100%);
  transition: 0.8s all;
}

.hero-btn:hover::after {
  transform: translateY(0%);
}

.hero-icons svg {
  color: var(--clr-primary-3);
  transition: all 0.8s;
}

.hero-icons svg:hover {
  color: var(--clr-primary-1);
}
