.toggle-btn {
    margin: 2rem 0;
}

#toggle_checkbox {
    display: none;
}

.toogle-label {
    position: relative;
    top: 50%;
    right: 0;
    left: 0;
    display: inline-block;
    width: 60px;
    height: 30px;
    margin: 0 0.5rem;
    transform: translateY(10%);
    cursor: pointer;
}

.toogle-label:before {
    top: 8px;
    left: 8px;
    width: 15px;
    height: 15px;
    border: 3px solid var(--clr-primary-1);
}

.toogle-label:after {
    top: 0;
    right: 0;
    width: 60px;
    height: 30px;
    border: 3px solid var(--clr-text);
}

.toogle-label:before,
.toogle-label:after {
    content: "";
    position: absolute;
    border-radius: 50px;
    box-sizing: border-box;
    transition: 0.5s ease top, 0.5s ease left, 0.5s ease right, 0.5s ease width, 0.5s ease height, 0.5s ease border-color;
}

#toggle_checkbox:checked+.toogle-label:before {
    top: 0;
    left: 0;
    width: 60px;
    height: 30px;
    border-color: var(--clr-text);
}

#toggle_checkbox:checked+.toogle-label:after {
    top: 8px;
    right: 8px;
    width: 15px;
    height: 15px;
    border-color: var(--clr-primary-1);
}