@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
.hero {
  min-height: calc(100vh - 5rem);
  display: grid;
  place-items: center;
  position: relative;
  overflow: hidden;
  background-color: #333;
}

.scene {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.scene path {
  fill: var(--clr-primary-2);
}

.info {
  z-index: 4;
}

.info h1 {
  color: var(--clr-primary-3);
  font-size: 4rem;
  line-height: 1;
}

.info h4 {
  color: #8e8e8e;
  line-height: 1;
}

.hero-btn {
  margin-top: 2rem;
  padding: 1rem 2rem;
  cursor: pointer;
  color: var(--clr-primary-3);
  border: 3px solid var(--clr-primary-1);
  background-color: transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.hero-icons a {
  color: var(--clr-primary-3);
}

.hero-btn span {
  transition: 2s all;
}

.hero-btn:hover span {
  color: var(--clr-primary-3);
}

.hero-btn::after {
  content: "";
  background-color: var(--clr-primary-1);
  width: 110%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: translateY(100%);
  transition: 0.8s all;
}

.hero-btn:hover::after {
  transform: translateY(0%);
}

.hero-icons svg {
  color: var(--clr-primary-3);
  transition: all 0.8s;
}

.hero-icons svg:hover {
  color: var(--clr-primary-1);
}

.center {
  margin: 0 auto;
  width: 50%;
}

.img {
  max-width: 50%;
  -webkit-clip-path: circle(48%);
          clip-path: circle(48%);
  text-align: center;
  background-color: var(--clr-primary-2);
  margin-bottom: 1rem;
}

.self-img {
  width: 60%;
  transform: translateY(10%);
}

.appear-self-img {
  -webkit-animation: appearSelfImg 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
          animation: appearSelfImg 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.about_description > p {
  margin: 2rem 0;
}

.about-btn-container {
  display: flex;
  justify-content: center;
}

.about-btn {
  margin-top: 2rem;
  padding: 1rem 2rem;
  cursor: pointer;
  color: var(--clr-text);
  border: 3px solid var(--clr-primary-1);
  background-color: transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.about-btn span {
  transition: 2s all;
}

.about-btn:hover span {
  color: var(--clr-primary-3);
}

.about-btn::after {
  content: "";
  background-color: var(--clr-primary-1);
  width: 110%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: translateY(100%);
  transition: 0.8s all;
}

.about-btn:hover::after {
  transform: translateY(0%);
}

@-webkit-keyframes appearSelfImg {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(10%);
  }
}

@keyframes appearSelfImg {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(10%);
  }
}

@media (min-width: 768px) {
  .about-btn-container {
    display: block;
  }
}

.home-vision {
  position: relative;
}

.vision-para {
  width: 85%;
  padding: 5% 5%;
  background-color: rgba(51, 49, 49, 0.8);
  border-radius: 4px;
  color: var(--clr-primary-3);
}

.rellax {
  position: absolute;
  z-index: -1;
}

#rellex-1 {
  top: 10%;
  left: 5%;
  z-index: 2;
}

#rellex-2 {
  bottom: -10%;
  right: 0%;
  z-index: 2;
}

#rellex-3 {
  top: 30%;
  right: 0%;
}

#rellex-4 {
  top: 50%;
}

@media (min-width: 768px) {
  .vision-para {
    width: 70%;
  }

  #rellex-1 {
    top: 0%;
    left: 15%;
  }

  #rellex-2 {
    top: 10%;
    right: 10%;
  }

  #rellex-3 {
    bottom: 20%;
    right: 13%;
  }

  #rellex-4 {
    bottom: 10%;
    left: 10%;
  }
}

.blog-row {
    margin: 2rem auto;
    width: 90%;
}

.blog-row>a {
    text-decoration: none;
}

.blog-desc {
    width: 100%;
    border: 1px solid var(--clr-text);
    padding: 3rem 5rem;
}

.blog-link {
    text-align: center;
}

.blog-date {
    font-size: 1rem;
    color: rgba(51, 51, 51, 0.75);
}

@media (min-width: 768px) {
    .blog-row {
        width: 50%;
    }
}
.porfolio-card {
  border: 1px solid var(--clr-text);
  overflow: hidden;
}

.porfolio-img {
  height: 260px;
  width: 100%;
  background-size: cover;
  background-position: top center;
  transition: all 2s ease-in-out;
}

.porfolio-card:hover .porfolio-img {
  background-position: bottom center;
}

.porfolio-content {
  padding: 2rem 1rem 2rem 1rem;
}

.porfolio-card > a {
  text-decoration: none;
}

.porfolio-description {
  font-size: 1rem;
  color: rgba(75, 75, 75, 0.7);
}

.more {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.nav {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 5rem;
  padding: 1rem 2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background: var(--clr-primary-3);
  z-index: 15;
}

.nav-center {
  width: 90%;
  margin: 0 auto;
}

.nav-header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 100%;
}

.nav-links {
  display: none;
}

.nav-btn {
  background: transparent;
  border: none;
  height: 50px;
  width: 50px;
  justify-self: end;
  cursor: pointer;
  z-index: 10;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--clr-primary-3);
  z-index: 6;
  display: grid;
  place-items: center;
  transform: translateX(-100%);
  transition: all 1s;
  overflow: hidden;
}

.sidebar-show {
  transform: translateX(0);
}

.close-btn {
  position: absolute;
  top: 2rem;
  right: 5rem;
  cursor: pointer;
  font-size: 3rem;
  color: var(--clr-primary-3);
  background: transparent;
  border: none;
  cursor: pointer;
}

.links {
  text-align: center;
  list-style: none;
  font-size: 1.5rem;
  line-height: 3rem;
  display: flex;
  flex-direction: column;
}

.icons {
  display: grid;
  text-align: center;
  list-style: none;
  grid-template-columns: repeat(3, 1fr);
  font-size: 2rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  padding: 2rem 0;
}

.links a,
.icons a {
  color: var(--clr-text);
}

.links a:hover,
.icons a:hover {
  color: var(--clr-primary-1) !important;
}

.logo {
  display: inline-block;
  font-size: 1.5rem;
  border-right: 2px solid rgba(219, 14, 14, 0.75);
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  -webkit-animation: typewriter 0.5s steps(7) 1s 1 normal both,
    blinkTextCursor 550ms steps(7) infinite normal;
          animation: typewriter 0.5s steps(7) 1s 1 normal both,
    blinkTextCursor 550ms steps(7) infinite normal;
}

.logout-btn {
  color: var(--clr-primary-3);
  background-color: var(--clr-primary-1);
  border: none;
  padding: 0.5rem 0.6rem;
}

.logout-btn:active {
  background-color: var(--clr-primary-2);
}

@-webkit-keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@-webkit-keyframes blinkTextCursor {
  from {
    border-right-color: rgba(219, 14, 14, 0.75);
  }

  to {
    border-right-color: transparent;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(219, 14, 14, 0.75);
  }

  to {
    border-right-color: transparent;
  }
}

footer{
    background-color: var(--clr-text);
}
.todo-list {
    display: none;
}

.blog {
    min-height: calc(100vh - 12rem);
}

.blog-list {
    width: 100%
}

.blog-row {
    margin-bottom: 2rem !important
}


@media (min-width: 768px) {
    .blog {
        min-height: calc(100vh - 11rem);
    }

    .blog .todo-list {
        display: inline-block;
        margin: 0 3.3% 0 0;
    }

    .col-9 {
        float: left;
        width: 60%;
    }

    .todo-list {
        width: 35%;
        margin: 5rem 1% 3rem 0
    }


    .blog-row {
        width: 50%;
    }

    .blog .blog-page-row {
        width: 100%;
    }

    .todo-list .todo {
        margin: 1rem 0;

    }
}
.admin_blog{
    min-height: calc(100vh - 11rem);
}

.admin_blog > h1{
    text-align: center;
}
.article {
    margin: 0 auto;
    width: 90%;
    min-height: calc(100vh - 12rem);
}

.article>h1 {
    text-align: center;
    margin: 3rem 0
}

.article>h2,
.article>h3 {
    margin: 1.5rem 0
}

.article>p {
    line-height: 1.5rem;
    letter-spacing: 1.1px;
    margin: 1.5rem 0;
    word-spacing: 3px;
}

.article>p:nth-child(3):first-letter {
    font-size: 1.9rem;
}

.article>pre {
    margin: 1rem 0;
}

.article  img{
    max-width: 100%;
}

@media (min-width: 1024px) {
    .article {
        margin: 0 auto;
        width: 800px;
    }
}
.login {
    min-height: calc(100vh - 11rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.input {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 1em;
    max-width: 400px;
    width: calc(100% - 2em);
    vertical-align: top;
    font-size: 1.7rem;
}

.input__field {
    position: relative;
    display: block;
    float: right;
    padding: 0.8em;
    width: 60%;
    border: none;
    border-radius: 0;
    background: #f0f0f0;
    color: #333;
    font-weight: 400;
    -webkit-appearance: none;
    /* for box shadows to show on iOS */
}

.input__field:focus {
    outline: none;
}

.input__label {
    display: inline-block;
    float: right;
    padding: 0 1em;
    width: 40%;
    color: #6a7989;
    font-weight: bold;
    font-size: 70.25%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.input__label-content {
    position: relative;
    display: block;
    padding: 1.6em 0;
    width: 100%;
}

.graphic {
    position: absolute;
    top: 0;
    left: 0;
    fill: none;
}

.input--nao {
    overflow: hidden;
    padding-top: 1em;
}

.input__field--nao {
    padding: 0.5em 0em 0.25em;
    width: 100%;
    background: transparent;
    color: #333;
    font-size: 1.5rem;
}

.input__label--nao {
    position: absolute;
    top: 0.4em;
    color: var(--clr-text);
    left: 0;
    display: block;
    width: 100%;
    text-align: left;
    padding: 0em;
    pointer-events: none;
    transform-origin: 0 0;
    transition: transform 0.3s 0.1s, color 1s;
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.graphic--nao {
    stroke: #333;
    pointer-events: none;
    transition: transform 0.7s, stroke 0.7s;
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.input__field--nao:focus+.input__label--nao,
.input--filled .input__label--nao {
    color: var(--clr-primary-1);
    transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
}

.input__field--nao:focus~.graphic--nao,
.input--filled .graphic--nao {
    transform: translate3d(-66.6%, 0, 0);
}

.login-error{
    color: var(--clr-primary-1);
    margin: 1.5rem 0;
}

.login-btn {
    padding: 1rem 1.3rem;
    cursor: pointer;
    border: 3px solid var(--clr-primary-1);
    background-color: transparent;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .7s all;
}

.login-btn::after {
    content: '';
    background-color: var(--clr-primary-1);
    width: 110%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: translateY(100%);
    transition:  transform .8s;
}

.login-btn:hover::after {
    transform: translateY(0%);
}

.login-btn:active::after{
    background-color: #960000;
}

.login-btn:hover{
    color: var(--clr-primary-3);
}
.input {
    position: relative;
    z-index: 1;
    display: inline-block;
    max-width: 400px;
    width: calc(100% - 2em);
    vertical-align: top;
    font-size: 1.7rem;
}

.input__field {
    position: relative;
    display: block;
    float: right;
    padding: 0.8em;
    width: 60%;
    border: none;
    border-radius: 0;
    background: #f0f0f0;
    color: #333;
    font-weight: 400;
    -webkit-appearance: none;
    /* for box shadows to show on iOS */
}

.input__field:focus {
    outline: none;
}

.input__label {
    display: inline-block;
    float: right;
    padding: 0 1em;
    width: 40%;
    color: #6a7989;
    font-weight: bold;
    font-size: 70.25%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.input__label-content {
    position: relative;
    display: block;
    padding: 1.6em 0;
    width: 100%;
}

.graphic {
    position: absolute;
    top: 0;
    left: 0;
    fill: none;
}

.input--nao {
    overflow: hidden;
    padding-top: 1em;
}

.input__field--nao {
    padding: 0.5em 0em 0.25em;
    width: 100%;
    background: transparent;
    color: #333;
    font-size: 1.5rem;
}

.input__label--nao {
    position: absolute;
    top: 0.4em;
    color: var(--clr-text);
    left: 0;
    display: block;
    width: 100%;
    text-align: left;
    padding: 0em;
    pointer-events: none;
    transform-origin: 0 0;
    transition: transform 0.3s 0.1s, color 1s;
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.graphic--nao {
    stroke: #333;
    pointer-events: none;
    transition: transform 0.7s, stroke 0.7s;
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.input__field--nao:focus+.input__label--nao,
.input--filled .input__label--nao {
    color: var(--clr-primary-1);
    transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
}

.input__field--nao:focus~.graphic--nao,
.input--filled .graphic--nao {
    transform: translate3d(-66.6%, 0, 0);
}
.admin{
    min-height: calc(100vh - 11rem);
}

.admin-card{
    border: 1px solid var(--clr-text);
    padding: 3rem 5rem;
    text-align: center;
    text-decoration: none;
}

textarea{
    overflow: hidden;
    resize: none;
    width: 100%;
    border: none;
}

textarea::-webkit-input-placeholder{
    
}

textarea:-ms-input-placeholder{
    
}

textarea::-ms-input-placeholder{
    
}

textarea::placeholder{
    
}

.blogedit {
    min-height: calc(100vh - 11rem);
}


.blogedit-btns{
    display: flex;
    justify-content: end;
    align-self: center;
}

.blogedit-btns >button{
    font-size: 15px;
    padding: 0.5rem 0.7rem;
    margin: 0 2rem;
    border: none;
    color: var(--clr-text);
    background-color: transparent;
}

.blogedit-btns >button:first-child{
    border: 2px solid rgb(4, 155, 37)
}

.blogedit-btns > button:nth-child(2){
    border: 2px solid var(--clr-primary-1);
}

.article  img{
    max-width: 100%;
}
.toggle-btn {
    margin: 2rem 0;
}

#toggle_checkbox {
    display: none;
}

.toogle-label {
    position: relative;
    top: 50%;
    right: 0;
    left: 0;
    display: inline-block;
    width: 60px;
    height: 30px;
    margin: 0 0.5rem;
    transform: translateY(10%);
    cursor: pointer;
}

.toogle-label:before {
    top: 8px;
    left: 8px;
    width: 15px;
    height: 15px;
    border: 3px solid var(--clr-primary-1);
}

.toogle-label:after {
    top: 0;
    right: 0;
    width: 60px;
    height: 30px;
    border: 3px solid var(--clr-text);
}

.toogle-label:before,
.toogle-label:after {
    content: "";
    position: absolute;
    border-radius: 50px;
    box-sizing: border-box;
    transition: 0.5s ease top, 0.5s ease left, 0.5s ease right, 0.5s ease width, 0.5s ease height, 0.5s ease border-color;
}

#toggle_checkbox:checked+.toogle-label:before {
    top: 0;
    left: 0;
    width: 60px;
    height: 30px;
    border-color: var(--clr-text);
}

#toggle_checkbox:checked+.toogle-label:after {
    top: 8px;
    right: 8px;
    width: 15px;
    height: 15px;
    border-color: var(--clr-primary-1);
}
.todo {}

.todo-add {
    display: flex;
}

.todo-btn {
    padding: 0rem 2rem;
    margin: 0 2rem;
    background-color: var(--clr-text);
    color: var(--clr-primary-3);
    border: none;
}

.todo-row {
    display: grid;
    grid-template-columns: 80% 20%;
    border: 1px solid var(--clr-text);
    margin: 1.5rem 0;
}

.todo-row>h2 {
    justify-self: center;
    align-self: center;
}

.todo-row-btn {
    padding: 2rem 1rem;
    background-color: var(--clr-text);
    color: var(--clr-primary-3);
    border: none;
    font-size: 2rem;
}
.svg {
  width: 98vw;
  height: calc(100vh - 11rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg svg {
  width: 100%;
  height: 60%;
}

#first-four,
#mid-zero,
#last-four {
  -webkit-animation: moveAround 2s infinite alternate;
  animation: moveAround 2s infinite alternate;
}

#right-eye,
#left-eye {
  position: relative;
}

#right-eyeball,
#left-eyeball {
  position: absolute;
}

#mouth-close {
  display: none;
}

@-webkit-keyframes moveAround {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-1rem, 0.2rem);
  }
}

@keyframes moveAround {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-1rem, 0.2rem);
  }
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;

}

body {
    font-size: 1.2rem;
    color: #333333;
    color: var(--clr-text);
    line-height: 1.8rem;
    font-family: 'lato', 'san-serif';

}

p {
    line-height: 1.8rem;
}

section {
    margin: 5rem 0;
}

::selection {
    color: #ffffff;
    color: var(--clr-primary-3);
    background: #333333;
    background: var(--clr-text);
}

.App{
    opacity: 0;
    transition: all 0.8s;
}

.render{
    opacity: 1;
}

.row::after {
    content: '';
    clear: both;
    display: table;
}

.row {
    margin: 0 auto;
    width: 95%;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.half {
    width: 100%;
    float: left;
}

.col-3 {
    width: 95%;
    margin: 3rem 0;
    float: left;
}

.hidden {
    display: hidden;
}

.center {
    justify-content: center;
}

a,
a:visited {
    text-decoration: underline;
    color: #333333;
    color: var(--clr-text);
    transition: 0.5s all;
}

a:hover {
    color: #ff0000;
    color: var(--clr-primary-1)
}

:root {
    --clr-primary-1: #ff0000;
    --clr-primary-2: #7e1631;
    --clr-primary-3: #ffffff;
    --clr-text: #333333;
    --font: 'Lato', sans-serif;
    --radius: 0.5rem;
}

.section-header {
    text-align: center;
    margin: 3rem 0;
}

.hero-icons svg {
    color: #ffffff;
    color: var(--clr-primary-3);
    transition: all .8s;
}

.hero-icons svg:hover {
    color: #ff0000;
    color: var(--clr-primary-1);
}

@media (min-width: 640px) {}

@media (min-width: 768px) {
    .nav-btn {
        display: none;
    }

    .nav-links {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 2rem;
        -webkit-column-gap: 2rem;
                column-gap: 2rem;
        justify-self: end;
    }

    .nav-center {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        width: 100%;
        margin: 0 auto;
    }

    .half {
        width: 50%;
    }

    .img {
        max-width: 60%;
    }

    .row {
        margin: 0 auto;
        width: 92%;
    }

    .col-3 {
        width: 30%;
        margin: 5rem 3.3% 3rem 0;
    }
}

@media (min-width: 1024px) {}

@media (min-width: 1200px) {}
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary{display:block;}audio,canvas,video{display:inline-block;}audio:not([controls]){display:none;height:0;}[hidden]{display:none;}html{font-family:sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;}body{margin:0;}a:focus{outline:thin dotted;}a:active,a:hover{outline:0;}h1{font-size:2em;margin:0.67em 0;}abbr[title]{border-bottom:1px dotted;}b,strong{font-weight:bold;}dfn{font-style:italic;}hr{box-sizing:content-box;height:0;}mark{background:#ff0;color:#000;}code,kbd,pre,samp{font-family:monospace,serif;font-size:1em;}pre{white-space:pre-wrap;}q{quotes:"\201C" "\201D" "\2018" "\2019";}small{font-size:80%;}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline;}sup{top:-0.5em;}sub{bottom:-0.25em;}img{border:0;}svg:not(:root){overflow:hidden;}figure{margin:0;}fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:0.35em 0.625em 0.75em;}legend{border:0;padding:0;}button,input,select,textarea{font-family:inherit;font-size:100%;margin:0;}button,input{line-height:normal;}button,select{text-transform:none;}button,html input[type="button"],input[type="reset"],input[type="submit"]{-webkit-appearance:button;cursor:pointer;}button[disabled],html input[disabled]{cursor:default;}input[type="checkbox"],input[type="radio"]{box-sizing:border-box;padding:0;}input[type="search"]{-webkit-appearance:textfield;box-sizing:content-box;}input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{-webkit-appearance:none;}button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}textarea{overflow:auto;vertical-align:top;}table{border-collapse:collapse;border-spacing:0;}
