.article {
    margin: 0 auto;
    width: 90%;
    min-height: calc(100vh - 12rem);
}

.article>h1 {
    text-align: center;
    margin: 3rem 0
}

.article>h2,
.article>h3 {
    margin: 1.5rem 0
}

.article>p {
    line-height: 1.5rem;
    letter-spacing: 1.1px;
    margin: 1.5rem 0;
    word-spacing: 3px;
}

.article>p:nth-child(3):first-letter {
    font-size: 1.9rem;
}

.article>pre {
    margin: 1rem 0;
}

.article  img{
    max-width: 100%;
}

@media (min-width: 1024px) {
    .article {
        margin: 0 auto;
        width: 800px;
    }
}