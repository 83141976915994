.svg {
  width: 98vw;
  height: calc(100vh - 11rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg svg {
  width: 100%;
  height: 60%;
}

#first-four,
#mid-zero,
#last-four {
  -webkit-animation: moveAround 2s infinite alternate;
  animation: moveAround 2s infinite alternate;
}

#right-eye,
#left-eye {
  position: relative;
}

#right-eyeball,
#left-eyeball {
  position: absolute;
}

#mouth-close {
  display: none;
}

@-webkit-keyframes moveAround {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  100% {
    -webkit-transform: translate(-1rem, 0.2rem);
    transform: translate(-1rem, 0.2rem);
  }
}

@keyframes moveAround {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  100% {
    -webkit-transform: translate(-1rem, 0.2rem);
    transform: translate(-1rem, 0.2rem);
  }
}
