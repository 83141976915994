.login {
    min-height: calc(100vh - 11rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.input {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 1em;
    max-width: 400px;
    width: calc(100% - 2em);
    vertical-align: top;
    font-size: 1.7rem;
}

.input__field {
    position: relative;
    display: block;
    float: right;
    padding: 0.8em;
    width: 60%;
    border: none;
    border-radius: 0;
    background: #f0f0f0;
    color: #333;
    font-weight: 400;
    -webkit-appearance: none;
    /* for box shadows to show on iOS */
}

.input__field:focus {
    outline: none;
}

.input__label {
    display: inline-block;
    float: right;
    padding: 0 1em;
    width: 40%;
    color: #6a7989;
    font-weight: bold;
    font-size: 70.25%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.input__label-content {
    position: relative;
    display: block;
    padding: 1.6em 0;
    width: 100%;
}

.graphic {
    position: absolute;
    top: 0;
    left: 0;
    fill: none;
}

.input--nao {
    overflow: hidden;
    padding-top: 1em;
}

.input__field--nao {
    padding: 0.5em 0em 0.25em;
    width: 100%;
    background: transparent;
    color: #333;
    font-size: 1.5rem;
}

.input__label--nao {
    position: absolute;
    top: 0.4em;
    color: var(--clr-text);
    left: 0;
    display: block;
    width: 100%;
    text-align: left;
    padding: 0em;
    pointer-events: none;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: -webkit-transform 0.3s 0.1s, color 1s;
    transition: transform 0.3s 0.1s, color 1s;
    -webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.graphic--nao {
    stroke: #333;
    pointer-events: none;
    -webkit-transition: -webkit-transform 0.7s, stroke 0.7s;
    transition: transform 0.7s, stroke 0.7s;
    -webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.input__field--nao:focus+.input__label--nao,
.input--filled .input__label--nao {
    color: var(--clr-primary-1);
    -webkit-transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
    transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
}

.input__field--nao:focus~.graphic--nao,
.input--filled .graphic--nao {
    -webkit-transform: translate3d(-66.6%, 0, 0);
    transform: translate3d(-66.6%, 0, 0);
}

.login-error{
    color: var(--clr-primary-1);
    margin: 1.5rem 0;
}

.login-btn {
    padding: 1rem 1.3rem;
    cursor: pointer;
    border: 3px solid var(--clr-primary-1);
    background-color: transparent;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .7s all;
}

.login-btn::after {
    content: '';
    background-color: var(--clr-primary-1);
    width: 110%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: translateY(100%);
    transition:  transform .8s;
}

.login-btn:hover::after {
    transform: translateY(0%);
}

.login-btn:active::after{
    background-color: #960000;
}

.login-btn:hover{
    color: var(--clr-primary-3);
}