@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;

}

body {
    font-size: 1.2rem;
    color: var(--clr-text);
    line-height: 1.8rem;
    font-family: 'lato', 'san-serif';

}

p {
    line-height: 1.8rem;
}

section {
    margin: 5rem 0;
}

::selection {
    color: var(--clr-primary-3);
    background: var(--clr-text);
}

.App{
    opacity: 0;
    transition: all 0.8s;
}

.render{
    opacity: 1;
}

.row::after {
    content: '';
    clear: both;
    display: table;
}

.row {
    margin: 0 auto;
    width: 95%;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.half {
    width: 100%;
    float: left;
}

.col-3 {
    width: 95%;
    margin: 3rem 0;
    float: left;
}

.hidden {
    display: hidden;
}

.center {
    justify-content: center;
}

a,
a:visited {
    text-decoration: underline;
    color: var(--clr-text);
    transition: 0.5s all;
}

a:hover {
    color: var(--clr-primary-1)
}

:root {
    --clr-primary-1: #ff0000;
    --clr-primary-2: #7e1631;
    --clr-primary-3: #ffffff;
    --clr-text: #333333;
    --font: 'Lato', sans-serif;
    --radius: 0.5rem;
}

.section-header {
    text-align: center;
    margin: 3rem 0;
}

.hero-icons svg {
    color: var(--clr-primary-3);
    transition: all .8s;
}

.hero-icons svg:hover {
    color: var(--clr-primary-1);
}

@media (min-width: 640px) {}

@media (min-width: 768px) {
    .nav-btn {
        display: none;
    }

    .nav-links {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 2rem;
        justify-self: end;
    }

    .nav-center {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        width: 100%;
        margin: 0 auto;
    }

    .half {
        width: 50%;
    }

    .img {
        max-width: 60%;
    }

    .row {
        margin: 0 auto;
        width: 92%;
    }

    .col-3 {
        width: 30%;
        margin: 5rem 3.3% 3rem 0;
    }
}

@media (min-width: 1024px) {}

@media (min-width: 1200px) {}